<template>
	<!-- 项目-我提的bug -->
  <div class="container" style="padding: 20px;">
    <GModule>
		<div class="product">
			<a-tabs v-model:activeKey="activeKey">
				<a-tab-pane v-for="(i,index) in productArr" :key="index" :tab="i.title"></a-tab-pane>
			</a-tabs>
		</div>
		<div class="condition-box">
			<div class="state-box">
				<p>BUG等级</p>
				<a-dropdown>
				    <template #overlay>
				        <a-menu selectable @click="handleMenuClick">
							<a-menu-item v-for="(sitem,sindex) in stateArr" :key="sindex">{{sitem.title}}</a-menu-item>
				        </a-menu>
				    </template>
				    <a-button>
						{{stateIndex ? stateArr[stateIndex].title : '全部'}}
				        <DownOutlined />
				    </a-button>
				</a-dropdown>
			</div>
			<div class="state-box">
				<p>指派给</p>
				<a-dropdown>
				    <template #overlay>
				        <a-menu selectable @click="handleManClick">
							<a-menu-item v-for="(mitem,mindex) in manArr" :key="mindex">{{mitem.title}}</a-menu-item>
				        </a-menu>
				    </template>
				    <a-button>
						{{manIndex ? manArr[manIndex].title : '全部'}}
				        <DownOutlined />
				    </a-button>
				</a-dropdown>
			</div>
			<div class="state-box">
				<p>处理状态</p>
				<a-dropdown>
				    <template #overlay>
				        <a-menu selectable @click="handleMenuClick2">
							<a-menu-item v-for="(nitem,nindex) in nstatusArr" :key="nindex">{{nitem.title}}</a-menu-item>
				        </a-menu>
				    </template>
				    <a-button>
						{{nstatusIndex ? nstatusArr[nstatusIndex].title : '全部'}}
				        <DownOutlined />
				    </a-button>
				</a-dropdown>
			</div>
			<div class="state-box">
				<p>提出时间</p>
				<a-dropdown>
				    <template #overlay>
				        <a-menu selectable @click="handleCreatTime">
							<a-menu-item v-for="(citem,cindex) in createTimeArr" :key="cindex">{{citem.title}}</a-menu-item>
				        </a-menu>
				    </template>
				    <a-button>
						{{createTimeIndex ? createTimeArr[createTimeIndex].title : '今天'}}
				        <DownOutlined />
				    </a-button>
				</a-dropdown>
			</div>
			<a-button class="btn clo1">提BUG</a-button>
			<a-button class="btn clo3">批量确认解决</a-button>
			<a-button type="danger" class="btn">批量删除</a-button>
		</div>
		<a-table 
			class="ttable" 
			sticky 
			:columns="activeKey == 1 ? columnsHeAll : columnsHe" 
			:data-source="data" 
			:scroll="{ x: 500,y:250 }" 
			bordered
			:pagination="false"
			:row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }">
		  <template #bodyCell="{ column ,record}">
				<template v-if="column.key === 'operation'">
					<div class="caozuo" v-if="activeKey == 1">
						<p>详情</p>
					</div>
					<div class="caozuo" v-else>
						<p>确认解决</p>
						<p>详情</p>
						<p>删除</p>
					</div>
				</template>
		  </template>
		</a-table>
		<div class="documentFt">
		  <a-pagination size="small" :total="50" />
		</div>
    </GModule>
  </div>
</template>
<script>
export default {
  name: 'TaskProjectBug',
  data(){
    return {
	    productArr:[
		    {title:"我提的bug"},
			{title:"全部"},
	    ], // 需求tab标签页
		activeKey:0,
		stateArr:[
			{title:"全部"},
		    {title:"一级"},
		    {title:"二级"},
		    {title:"三级"},
	    ] ,// 状态列表
		stateIndex:0,
		manArr:[
			{title:"全部"},
		    {title:"张三"},
		    {title:"李四"},
		    {title:"王五"},
		] ,// 状态列表
		manIndex:0,
		nstatusArr:[
			{title:"全部"},
		    {title:"未解决"},
		    {title:"已解决"},
		    {title:"已确认解决"},
			{title:"已反馈"}
		] ,// 创建时间列表
		nstatusIndex:0,
		createTimeArr:[
			{title:"今天"},
		    {title:"本周"},
		    {title:"本月"},
		    {title:"一个月前"}
		] ,// 创建时间列表
		createTimeIndex:0,
		columnsHe: [{
		    title: 'BUGID',
		    width: 80,
		    dataIndex: 'index',
		    key: 'index',
		    fixed: 'left',
		  }, {
		    title: 'BUG描述',
		    width: 100,
		    dataIndex: 'name',
		    key: 'describe',
		    fixed: 'left',
		  }, {
		    title: '图片',
		    width: 120,
		    dataIndex: 'image',
		    key: 'image'
		  }, {
		    title: 'BUG等级',
		    width: 100,
		    dataIndex: 'age',
		    key: 'age'
		  }, {
		    title: '指派给',
		    width: 100,
		    dataIndex: 'age',
		    key: 'age'
		  }, {
		    title: '提出时间',
		    width: 100,
		    dataIndex: 'age',
		    key: 'age'
		  }, , {
		    title: '处理状态',
		    width: 100,
		    dataIndex: 'age',
		    key: 'age'
		  }, {
		    title: '反馈信息',
		    width: 100,
		    dataIndex: 'age',
		    key: 'age'
		  },{
		    title: '操作',
		    key: 'operation',
		    fixed: 'right',
		    width: 180,
		}],
		data: [{
		  key: '1',
		  name: 'John Brown',
		  age: 32,
		  address: 'New York No. 1 Lake Park',
		}, {
		  key: '2',
		  name: 'Jim Green大家聚聚军军军军军军军军军军军军军军军军军军军军军军军军军军军军军军军军军军军军军军军军军军军军军军军',
		  age: 42,
		  address: 'London No. 1 Lake Park',
		}, {
		  key: '3',
		  name: 'Joe Black',
		  age: 32,
		  images:[],
		  address: 'Sidney No. 1 Lake Park',
		}, {
		  key: '4',
		  name: 'Jim Red',
		  age: 32,
		  address: 'London No. 2 Lake Park',
		},{
		  key: '5',
		  name: 'John Brown',
		  age: 32,
		  address: 'New York No. 1 Lake Park',
		}, {
		  key: '6',
		  name: 'Jim Green',
		  age: 42,
		  address: 'London No. 1 Lake Park',
		}, {
		  key: '7',
		  name: 'Joe Black',
		  age: 32,
		  address: 'Sidney No. 1 Lake Park',
		}, {
		  key: '8',
		  name: 'Jim Red',
		  age: 32,
		  address: 'London No. 2 Lake Park',
		}],
		value1:'这双',
		columnsHeAll: [{
		    title: 'BUGID',
		    width: 80,
		    dataIndex: 'index',
		    key: 'index',
		    fixed: 'left',
		  }, {
		    title: 'BUG描述',
		    width: 100,
		    dataIndex: 'name',
		    key: 'describe',
		    fixed: 'left',
		  }, {
		    title: '图片',
		    width: 120,
		    dataIndex: 'image',
		    key: 'image'
		  }, {
		    title: 'BUG等级',
		    width: 100,
		    dataIndex: 'age',
		    key: 'age'
		  },  {
		    title: '提出者',
		    width: 100,
		    dataIndex: 'age',
		    key: 'age'
		  },{
		    title: '指派给',
		    width: 100,
		    dataIndex: 'age',
		    key: 'age'
		  }, {
		    title: '提出时间',
		    width: 100,
		    dataIndex: 'age',
		    key: 'age'
		  }, , {
		    title: '处理状态',
		    width: 100,
		    dataIndex: 'age',
		    key: 'age'
		  },{
		    title: '操作',
		    key: 'operation',
		    fixed: 'right',
		    width: 180,
		}],
    }
  },
  methods:{
	  handleMenuClick(e){
		  this.stateIndex = e.key
		  console.log("选择",this.stateIndex)
	  },
	  handleManClick(e){
		  this.manIndex = e.key
		  console.log("选择",this.manIndex)
	  },
	  handleMenuClick2(e){
		  this.nstatusIndex = e.key
		  console.log("选择",this.nstatusIndex)
	  },
	  handleCreatTime(e){
		  this.createTimeIndex = e.key
		  console.log("选择",this.createTimeIndex)
	  },
	  openSwiper(){
		  // 弹出图片列表
		  this.$store.commit('setSwiper',1)
	  }
  }

}
</script>

<style scoped lang="scss">
.product{
	display: flex;
	.add{
		width: 40px;
		height: 40px;
		margin-left: 20px;
		.ant-btn-icon-only{
			width: 40px !important;
			height: 40px !important;
			padding:0 !important;
		}
	}
}
.condition-box{
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	margin: 5px 0 10px 0;
	.state-box{
		display: flex;
		justify-content: flex-start;
		align-items: center;
		margin-bottom: 5px;
		p{
			margin: 0 10px 0 20px;
		}
	}
	.btn{
		margin-left: 20px;
		margin-bottom: 5px;
	}
}
.ttable{
	margin-top:0;	
	.txt{
		display: flex;
		align-items: center;
		.red{
			width: 10px;
			height: 10px;
			border-radius: 50%;
			background-color: red;
			margin-right: 5px;
		}
	}
}
.documentFt{
    margin-top:20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.caozuo{
	display: flex;
	justify-content: space-around;
	align-items: center;
	p{
		margin: 0;
		font-size: 12px;
		color: #407cff;
	}
}


</style>